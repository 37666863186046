.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px; 
  height: 30px;
  /* background: rgb(255, 0, 191); */
  background: linear-gradient(90deg, rgba(81, 124, 241, 1) 0%, rgba(0, 142, 236, 1) 40%, rgba(81, 164, 241, 1) 60%, rgba(81, 124, 241, 1) 100%);
  justify-content: center;
}
.headerLeft {
  position: relative;
  flex: 0.2;
  justify-items: left;
  display: flex;
  align-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: rgb(0, 0, 0);
  align-items: center;
}
.headerCenter p{
  font-size: 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
}
.headerCenterIcon{
  position: relative;
  justify-content: center;
  width: 30px;
  top: 5px;
  right: 20px;
}
.headerCenter img{
  position: relative;
  scale: 0.35;
}
.headerCenter {
  position: relative;
  display: flex;
  align-content: center;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.headerRight{
  position: relative;
  flex: 0.2;
  display: flex;
  justify-items: right;
}


.profileIcon{
  position: relative;
  justify-content: right;
  right: -40px;
  top: -20px;
  scale: 0.4;
}
.dropContainer{
  position: absolute;
  top: 200px;
  right: -50px;
  background-color: rgb(5, 5, 5);
  border-radius: 25px;
  padding: 10px 20px;
  width: 500px;
  padding: 50px;
  justify-items: left;
}

h3{
  color:rgb(255, 255, 255);
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 50px;
}

h3 span{
  font-size: 50px;
  font-weight: 100;
}

.dropLine{
  border-top: 2px solid yellow;
  border-radius: 15px;
  padding-top: 20px;
  width: 100%;
  justify-items: center;
}
.dropContainer ul{
  padding: 0;
}
.dropContainer ul li{
  padding: 10px 0;
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(255, 255, 255);
  list-style-type: none
}

.dropContainer ul li:hover p{
  color: rgb(252, 141, 228);
  cursor: pointer;
}

.dropContainer.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(0.5) ease;
}
.dropContainer.inactive{
  display: none;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(0.5) ease;
}