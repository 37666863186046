.app {
    top:0px;
    left:0px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: 	#000000;
  }
  
  .app__body {
    width: 100%;
    min-height: 700px;
    height: 93vh;
    display: flex;
    flex-grow: 1;
    background-color: 	#000000;
  }
  .app__header{
    display: flex;
  }
  
  .new__profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #161515;
  }
  .new__profile p{
    background: linear-gradient(90deg, rgb(255, 215, 0) 0%, rgb(255, 187, 0) 50%, rgb(255, 208, 0) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;;
    font-size: 50px;
    margin: 20px;
  }
  .new__profile__form{
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: 0px solid;
    border-radius: 10px;
    align-items: center;
    width: 500px;
  }
  .form__field{
    margin: 10px;
    padding: 10px;
    border: 1px solid;
    border-radius: 25px;
    outline: none;
    background-color: black;
    font-size: 20px;
    width: inherit;
    color: grey;
  }
  .form__btn{
    margin: 10px;
    padding: 10px;
    border: 0px solid;
    border-radius: 25px;
    background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
    width: 300px;
  }