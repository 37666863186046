.profilePicture {
  margin: 10px 0;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
  border: 20px solid rgb(84, 144, 243);
  /* box-shadow: 0px 0px 5px 0px rgb(0, 221, 236); */
  width: 150px;
  height: 150px;
}

.profilePicture__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  display: inline-block;
}
