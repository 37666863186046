/* Container Styling */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    height: 100vh;
  }

  .loginheader {
    display: flex;
    flex-direction: row;
    padding: 10px; 
    /* background: rgb(255, 0, 191); */
    justify-content: center;
    align-items: center;
    scale:110%;
    background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .loginheaderCenter {
    position: relative;
    display: flex;
    align-content: center;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    flex:0.06;
  }
  .headerCenter{
    height: 15px;
  }
  .headerCenter p{
    margin: 0px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
  .headerCenterIcon{
    position: relative;
    justify-content: center;
    width: 30px;
    top: 5px;
    right: 20px;
  }
  .headerCenter img{
    position: relative;
    scale: 0.35;
  }
  .loginappLogo{
    position: relative;
    object-fit: cover;
  }
  
  /* Form Wrapper Styling */
  .formwrapper {
    background-color: #050505;
    padding: 0px 20px;
    border-radius: 25px;
    width: 100%;
    max-width: 400px;
  }
  
  /* Form Title Styling */
  .formtitle {
    text-align: center;
    margin-top: 50px;
    background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  /* Error Message Styling */
  .error-message {
    color: red;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Form Group Styling */
  .form-group {
    margin-bottom: 10px;
  }
  
  /* Label Styling */
  .form-label {
    color: aqua;
  }
  
  /* Input Styling */
  .forminput {
    width: 93%;
    margin: 10px  0px;
    border-radius: 25px;
    border: none;
    border: 2px solid #2e2e2e;
    background-color: transparent;
    outline: none;
    color: #c8f5ff;
    padding: 12px 15px;
    background-color: #000000;
  }
  input::-webkit-input-placeholder { /* Chrome, Safari, Edge */
    color: white;
  }
  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(235, 0, 0);
  }
  
  /* Button Styling */
  .formbutton {
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    border: none;
    background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
    color: #000000;
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  /* Login Redirect Styling */
  .loginredirect {
    text-align: center;
    color: #8d8d8d;
    padding: 5px;
  }
  .loginLink{
    color: #8d8d8d;
  }

  /* Button Styling for Social Media Logins */
.social-login-btn {
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Center-align icon and text vertically */
  justify-content: center; /* Center the content within the button */
  width: 100%; /* Full width button */
  max-width: 300px; /* Set a maximum width for the button */
  padding: 12px 15px; /* Add space inside the button */
  border: 2px solid #2e2e2e; /* Light border around the button */
  border-radius: 25px; /* Rounded corners for the button */
  background-color: black; /* Set background to white */
  color: white; /* Text color for the button */
  font-size: 16px; /* Font size for the button text */
  font-weight: bold; /* Make the text bold */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove underline from text (for anchor tags) */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effects */
  margin: 20px;
}

/* Hover Effect for Buttons */
.social-login-btn:hover {
  transform: translateY(-3px); /* Slight lift effect */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); /* Add shadow for hover */
}

/* Icon Styling for Social Media Logins */
.social-login-btn img {
  width: 20px; /* Set icon width */
  height: 20px; /* Set icon height */
  margin-right: 10px; /* Space between the icon and text */
}

.social-login{
  align-content: center;
  justify-items: center;
}

.app-login{
  margin-top: 20px;
  padding: 20px;
  border-top: 2px solid rgb(32, 32, 32);
  overflow: hidden;
}
.app-login form{
  display: flex;
  flex-direction: column;
  padding: 10px;
}