.post {

  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
  /* background-color: #080808; */
  background-color: transparent;
  display: flex;
  flex-direction: column;
  background-color: #050505;
  width: 800px;
}

.post__profilePicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}


.post__attachment {
  margin-top: 10px;
  max-width: 100%;
  max-height: 300px;
}

.post__top {
  display: flex;
  flex-direction: row;
  color: white;
  font-weight: bold;
  font-size: large;
}
.post__content {
  margin: 0px 20px;
  font-size: large;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.post__content__head{
  display: flex;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  justify-content: left;
  border-bottom: 2px solid #ff634727;
}
.post__content__head p{
  font-size: 20px;
  margin: 10px;
  color: rgb(215, 196, 250);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.post__content__body{
  margin: 10px;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  justify-content: left;
  font-size: large;
}
.post__content__foot{
  display: flex;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  justify-content: right;
}
.post__content__foot p{
  font-size: 15px;
  padding: 10px;
  color: antiquewhite;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}