.newPost {
  background-color: transparent;
  padding: 10px;
  border-radius: 50px;
  /* box-shadow: 0px 0px 20px 10px #000000; */
  overflow: hidden;
  align-self: last baseline;
  /* border: 3px solid rgba(0, 220, 236, 0.856); */
  box-shadow:  0 10px 20px -1px rgb(81, 164, 241), 0 3px 3px -1px rgb(112, 102, 247);
  max-height: max-content;
  width: inherit;
}
.newPost_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  
}
.newPost__firstpage{
  display: flex;
  flex-grow: 1;
  align-items: end;

}

.newPost__title{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  flex-grow: 1;
  display: flex;
}
.newPost__title input{
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  flex-grow: 1;
  outline: none;
}
.newPost__next{
  color: #fff;
  margin: 10px;
  align-content: center;
}
.newPost__next img{
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.newPost__content{
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

}
.newPost__content textarea{
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  min-height: 100px;
  outline: none;
}
.newPost__send{
  position: relative;
  margin: 10px;
}
.newPost__attach{
  position: relative;
  margin: 10px;
}
.newPost__attachmentPreview{
  color: #e5a8f5;
  display: flex;
  align-items: center;
}
/* Add this to your existing CSS file */
.removeAttachmentButton {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 10px;
  color: #ffffff;
}

.uploadProgress {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 2px;
  margin-top: 10px;
}

.progressBar {
  height: 5px;
  background-color: #4caf50;
  border-radius: 2px;
}