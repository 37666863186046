
.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 100%;
    width: 100%;
    margin: 0;
    top: 0;
    left: 0;
}