.feed {
  padding: 10px;
  flex: 0.85;
  overflow: hidden;
  padding-top: 0px;
  display: Grid;
  /* border: 1px solid #ff00ff; */
  justify-content: left;
}

.feed_container{
  display: Grid;
  width: 1100px;
  /* background: linear-gradient(#000000, #000000, #010101, #080808); */
  padding: 0px 50px;
  border-radius: 5px;
  height: 92vh;
  justify-items: center;
}

.feed_post::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.feed_post{
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  justify-content: center;
}
.profile__view{
  color : #fff;
}
.profile__details{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  justify-content: center;
  height: 100%;
}
.profile__details h2{
  background: linear-gradient(90deg, rgb(255, 215, 0) 0%, rgb(255, 187, 0) 50%, rgb(255, 208, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.profile__details p{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
}