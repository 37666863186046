
.sidebar {
  padding: 10px;
  flex: 0.15;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.sidebar__profile {
  justify-items: left;
  margin: 0px 10px;
}
.sidebar__container {
  justify-items: center;
  background-color: 	#050505;
  border-radius: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.sidebar__menuIcons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  margin: 10px;
  border-bottom: 1px solid rgba(0, 220, 236, 0.548);
  background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
  border-radius: 25px;
}
.sidebar__menuIcon {
  color:#fff;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.title__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.title__appLogo
{
  height: 100px;
  margin: 10px;
}
.sidebar__channels {
  flex-grow: 1;
  height: 50vh;
  padding-top: 20px;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}
.sidebar__channels::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.sidebar__channel {
  text-align: center;
  margin: 10px 0;
  align-content: center;  
  background-color: #111618;
  /* color:  rgb(121, 237, 245); */
  color: #a1dada;
  border-radius: 25px;
  /* box-shadow: 0 0px 8px 0 rgba(81, 124, 241, 1), 0 6px 20px 0 rgba(81, 164, 241, 1);  */
  height: 45px;
  font-size: larger;
  cursor: pointer;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.sidebar__channel--selected {
  /* box-shadow: 0 3px 10px 0 rgb(145, 65, 250), 0 -3px 3px 1px rgb(33, 66, 253); */
  /* box-shadow:  0 -3px 3px -1px rgb(81, 164, 241), 0 3px 3px -1px rgb(112, 102, 247); */
  /* box-shadow: inset 0 -3px 7px 0px rgb(81, 164, 241), inset 0 3px 10px 0px rgb(112, 102, 247); */
  box-shadow: inset 0 0px 12px 2px rgb(0, 0, 0);
  border: 3px solid rgba(0, 220, 236, 0.856);
  background: linear-gradient(90deg, rgba(81, 164, 241, 0.2) 0%, rgba(0, 142, 236, 0.2) 50%, rgba(81, 124, 241, 0.2) 100%);
  /* color: black; */
}

.sidebar__username{
  background: linear-gradient(90deg, rgb(255, 215, 0) 0%, rgb(255, 187, 0) 50%, rgb(255, 208, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0px;
  margin-bottom: 10px;
}

/* popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup__content {
  background-color: #161515;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.popup__content h1{
  background: linear-gradient(90deg, rgb(255, 215, 0) 0%, rgb(255, 187, 0) 50%, rgb(255, 208, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin: 10px;
}
.popup__field{
  background-color: black;
  border-radius: 25px;
  margin: 10px;
  width: auto;
}
.popup__field input{
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  height: 100%;
  outline: none;
  width: auto;
}
.popup__btn{
  background: linear-gradient(90deg, rgb(81, 164, 241) 0%, rgb(0, 142, 236) 50%, rgb(81, 124, 241) 100%);
  border: none;
  border-radius: 25px;
  color: #131313;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  padding: 10px;
  width: auto;
}
.popup__close{
  top: 10px;
  right: 10px;
  width: 100%;
}
.popup__close img{
  position: relative;
  height: 20px;
  cursor: pointer;
}