/* Container Styling */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Form Wrapper Styling */
  .form-wrapper {
    background-color: #080808;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
  }
  
  /* Form Title Styling */
  .form-title {
    text-align: center;
    margin-bottom: 20px;
    color: aqua;
  }
  
  /* Error Message Styling */
  .error-message {
    color: red;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .success-message {
    color: green;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Form Group Styling */
  .form-group {
    margin-bottom: 10px;
  }
  
  /* Label Styling */
  .form-label {
    color: aqua;
  }
  
  /* Input Styling */
  .form-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /* Button Styling */
  .form-button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: aqua;
    color: #333;
    cursor: pointer;
  }
  
  /* Login Redirect Styling */
  .login-redirect {
    text-align: center;
    margin-top: 20px;
    color: aqua;
  }

  .link {
    color: rgb(250, 250, 137);
  }